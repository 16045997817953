import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Link,
  //    NavLink, useHistory, useLocation
} from "react-router-dom";
//import Swal from "sweetalert2";
import "../css/landing.css";
import { Collapse } from "react-bootstrap";
//import Rightcontainer from "../Components/Rightcontainer";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";
import BottonMenu from "./Bottom";

export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          //window.location.href = "/login";
          //window.location.reload()
          //history.push("/login")
        }
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      //window.location.reload()
    }
    role();
    fetchData();
  }, []);

  return (
    <>
      <Header user={userAllData} />

      <div className="leftContainer mb_space">
      <div className='pt-5 mt-5   Orher_page_main_section'>
        <div className="main-area">
          <div className='gameCard pt-2'>
          {WebSitesettings.homeMsg &&
            <div className='alrt_msg_div'>
              <b>
                {WebSitesettings.homeMsg}
              </b>
            </div>
          }
          </div>
          
      

          
   <a href="https://youtu.be/vD23mxozYy8?si=4C7zmXUKzlxqd5PP" target="_blank">
           <div className="collapseCard">
              
                <div
                  className="collapseCard-body"
                  style={{
                    height: "64px",
                    opacity: 1,
                    transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",
                  }}
                >
                  <div className="collapseCard-text text-dark">
                    {" "}
                    <span className=" text-danger">
                     How To Win Money?
                    </span>
                  </div>
                </div>
              

              <div
                className="collapseCard-header"
                style={{ left: "22px", transition: "left 0.3s ease 0s" }}
              >
                <picture>
                  <img
                    height="15px"
                    width="14px"
                    src="/Images/LandingPage_img/youtube.png"
                    alt=""
                  />
                </picture>
                <div className="collapseCard-title ml-1 mt-1">VIDEO HELP</div>
              </div>
            </div>
            </a>
             <br></br>
                    
 
            

          <section className="games-section p-3">
            <div className="d-flex align-items-center games-section-title">
              Our Tournaments
            </div>
            <div className="games-section-headline mt-2 mb-1">
              <div className="games-window ">
                <Link
                  className="gameCard-container"
                  to={`/Homepage/Ludo%20Classics`}
                >
                  <span className="d-none blink text-success d-block text-right">
                    ◉ Min.50-Max.50k LIVE
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/Ludo Classic.webp"
                      }
                      alt=""
                    />
                  </picture>
                  {/* <div className="gameCard-title">
                    <span className="d-none blink text-success d-block text-right">
                      ◉ LUDO CLASSICS
                    </span>
                  </div> */}
                  {/* <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div> */}
                </Link>

                {/* <Link className="gameCard-container" to={`/Homepage/Ludo%20Popular`}> */}
                <Link className="gameCard-container" to={`/Homepage/Ludo%20Popular`}>
                  <span className="d-none blink text-success d-block text-right">
                    ◉ in.50-Max.50k LIVE
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/Ludo Popular.webp"
                      }
                      alt=""
                    />
                  </picture>
                  {/*
                 <div className="gameCard-title">
                    <span className="d-none blink text-success  d-block text-right">
                      ◉ TEAM LUDO
                    </span>
                  </div>
                  */}
                 <div className="goverlay">
                    <div className="text">LIVE</div>
                  </div>
                </Link>
               {/*/Homepage/Ludo%201Goti*/}
                <Link className="gameCard-container" to={``}>
                  <span className="d-none blink text-success d-block text-right">
                    ◉ Comming Soon
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/Ludo Classic 1G.webp"
                      }
                      alt=""
                    />
                  </picture>
                  {/*
                  <div className="gameCard-title">
                    <span className="d-none blink text-success  d-block text-right">
                      ◉ Ludo Classic 1G
                    </span>
                  </div>
                  */}
                  <div className="goverlay">
                    <div className="text">LIVE</div>
                  </div>
                </Link>
                {/*/Homepage/Ludo%20Snake*/}
                <Link className="gameCard-container" to={``}>
                  <span className="d-none blink text-success d-block text-right">
                    ◉ Comming Soon
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/Ludo Classic 2G.webp"
                      }
                      alt=""
                    />
                  </picture>
                  {/*
                  <div className="gameCard-title">
                    <span className="d-none blink text-success  d-block text-right">
                      ◉ Ludo Classic 2G
                    </span>
                  </div>
                  */}
                  <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div>
                </Link>



              </div>
            </div>
          </section>
          
          <div className="downloadButton">
            <Downloadbutton />
          </div>

        </div>
        </div>
      </div>
      {/* // <div className='rightContainer'>
            //     <Rightcontainer/>
            // </div> */}
    </>
  );
}
